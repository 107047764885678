import { Dropdown, IconButton, Menu, MenuButton } from "@mui/joy";

export function CollapsedSidebarHoverMenu({
  icon,
  content,
  open,
  setOpen,
}: {
  icon: React.ReactNode;
  content: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  return (
    <Dropdown
      open={open}
      onOpenChange={(_, isOpen) => {
        isOpen && setOpen(isOpen);
      }}
    >
      <MenuButton
        sx={{
          border: "0",
          p: 0,
        }}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <IconButton>{icon}</IconButton>
        {open && <div className="absolute -mr-10 h-16 w-10" />}
      </MenuButton>
      <Menu
        placement="right-start"
        onMouseEnter={() => {
          setOpen(true);
        }}
        onMouseLeave={() => {
          setOpen(false);
        }}
        sx={{
          width: "300px",
          height: "600px",
        }}
      >
        {content}
      </Menu>
    </Dropdown>
  );
}
