import { Typography, LinearProgress } from "@mui/joy";
import { Stack } from "@mui/system";
import { differenceInDays } from "date-fns";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { type OrganizationPhase } from "../../../../backend/src/api/organization/organizationTypes";

interface PhaseContainerProps {
  phase: OrganizationPhase;
  start: string;
  end: string;
}
export function PhaseContainer({ phase, start, end }: PhaseContainerProps) {
  const { t } = useTranslation();

  const isPilot = phase === "PILOT";

  const { range, remaining } = useMemo(() => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const now = new Date();
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      return { range: 0, remaining: 0 };
    }
    return {
      range: differenceInDays(endDate, startDate),
      remaining: differenceInDays(endDate, now),
    };
  }, [start, end]);

  // only show pilot if it is about to run out
  if ((isPilot && remaining > 14) || range === 0) return null;
  return (
    <Stack gap={1}>
      <Stack direction="row" flex={1} justifyContent="space-between">
        <Typography level="body-sm" fontWeight="500">
          {isPilot ? t("trial.pilotActive") : t("trial.trialActive")}
        </Typography>
        <Typography level="body-sm">
          {t("trial.daysLeft", { count: Math.max(remaining, 0) })}
        </Typography>
      </Stack>
      <LinearProgress
        determinate
        sx={{ backgroundColor: "rgba(30, 93, 194, .2)" }}
        value={Math.min(Math.max(((range - remaining) / range) * 100, 1), 100)}
        color="primary"
        variant="soft"
      />
    </Stack>
  );
}
