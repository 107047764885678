import { Add, AutoAwesome, Search } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Dropdown,
  IconButton,
  Input,
  List,
  Menu,
  MenuButton,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/joy";
import Fuse from "fuse.js";
import { useEffect, useMemo, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";
import type { Workflow } from "../../../../backend/src/api/workflow/workflowTypes.ts";
import { trpc } from "../../lib/api/trpc/trpc.ts";
import { useTranslation } from "../../lib/i18n";
import { optimisticWorkflowReorder } from "../../lib/optimistic/reordering.ts";
import { useGuide } from "../onboarding/useGuide.tsx";
import { DelayedLoader } from "../util/DelayadLoader";
import { DemoWorkflowItem } from "../workflows/DemoWorkflowItem.tsx";
import { WorkflowCreationWizard } from "../workflows/WorkflowWizard.tsx";

import { NewWorkflowButton } from "./tree/NewWorkflowButton.tsx";
import { WorkflowItem } from "./tree/WorkflowItem.tsx";
import type { Department } from "../../../../backend/src/api/organization/department/departmentTypes.ts";

export function OrganizationTree({ onAction }: { onAction?: () => void }) {
  const { t } = useTranslation();

  const { data: departments } = trpc.organization.department.all.useQuery();
  const utils = trpc.useUtils();

  const { data: favoriteWorkflows } = trpc.workflows.favorites.useQuery();

  const { mutateAsync: updateWorkflowDepartmentAndPosition } =
    trpc.workflows.updateDepartmentAndPosition.useMutation({
      onMutate: async (input) => {
        await utils.organization.department.all.cancel();

        const currentDepartments = utils.organization.department.all.getData()!;

        const updatedDepartments = optimisticWorkflowReorder(
          currentDepartments,
          input.workflow.id,
          input.sourceDepartmentId,
          input.workflow.departmentId,
          input.workflow.index
        );

        utils.organization.department.all.setData(
          undefined,
          updatedDepartments
        );

        return { previousDepartments: currentDepartments };
      },
      onError: (err, input, ctx) => {
        utils.organization.department.all.setData(
          undefined,
          ctx!.previousDepartments
        );
      },
      onSettled: async () => {
        await utils.organization.department.all.invalidate();
      },
      onSuccess: async () => {
        toast.success(t("workflowMoved"));
      },
    });
  const searchBarRef = useRef<HTMLInputElement | null>(null);
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");

  const [workflowWizardOpen, setWorkflowWizardOpen] = useState(false);

  const filteredDepartments = useMemo(() => {
    if (searchValue.trim() === "") return departments;

    const departmentsWithFilteredWorkflows = departments?.map((department) => {
      const fuse = new Fuse(department.workflows, {
        keys: ["name", "description"],
      });

      const filteredWorkflows = fuse
        .search(searchValue.trim())
        .map((result) => result.item);

      return {
        ...department,
        workflows: filteredWorkflows,
      };
    });

    return departmentsWithFilteredWorkflows?.filter(
      (department) => department.workflows.length > 0
    );
  }, [departments, searchValue]);

  if (!searchVisible && searchValue) setSearchValue("");

  if (!departments) return <DelayedLoader />;

  function moveWorkflowToDepartment(
    workflowId: string,
    sourceDepartmentId: string,
    targetDepartmentId: string,
    index: number
  ) {
    return updateWorkflowDepartmentAndPosition({
      workflow: { id: workflowId, departmentId: targetDepartmentId, index },
      sourceDepartmentId,
    });
  }

  return (
    <div id="sidebarWorkflowsSection">
      <WorkflowCreationWizard
        onClose={() => {
          setWorkflowWizardOpen(false);
          onAction?.();
        }}
        open={workflowWizardOpen}
      />
      <div className="gap flex flex-row items-center">
        <Typography
          level="title-md"
          color="neutral"
          sx={{
            px: 2,
            py: 1,
          }}
        >
          {t("workflows")}
        </Typography>

        <Dropdown>
          <MenuButton
            slots={{
              root: "div",
            }}
            sx={{
              // icon font size
              "--Icon-fontSize": "18px",
            }}
          >
            <IconButton size="sm">
              <Add fontSize="small" />
            </IconButton>
          </MenuButton>

          <Menu>
            <MenuItem
              onClick={() => {
                setWorkflowWizardOpen(true);
              }}
            >
              <div className="flex flex-row items-center gap-2">
                <div className="text-blue-600">
                  <AutoAwesome htmlColor="inherit" />{" "}
                </div>
                {t("workflowEditor.newButton.auto")}
              </div>
            </MenuItem>
            <MenuItem>
              <NewWorkflowButton onCreated={onAction}>
                {t("workflowEditor.newButton.manual")}
              </NewWorkflowButton>
            </MenuItem>
          </Menu>
        </Dropdown>
        <Tooltip title={t("search")} placement="top" arrow>
          <IconButton
            size="sm"
            variant={searchVisible ? "solid" : "plain"}
            color={searchVisible ? "primary" : "neutral"}
            onClick={() => {
              setSearchVisible((prev) => !prev);
              setSearchValue("");

              !searchVisible && searchBarRef.current?.focus();
            }}
          >
            <Search fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
      <div
        className="px-3 transition-all"
        style={{
          height: searchVisible ? "auto" : "0",
          opacity: searchVisible ? 1 : 0,
        }}
      >
        <Input
          type="text"
          size="sm"
          placeholder={t("search")}
          slotProps={{
            input: {
              ref: searchBarRef,
            },
          }}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              setSearchVisible(false);
              setSearchValue("");
            }
          }}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>
      <DragDropContext
        onDragEnd={(result) => {
          if (!result.destination) return;
          if (
            result.destination.droppableId === result.source.droppableId &&
            result.destination.index === result.source.index
          )
            return;

          moveWorkflowToDepartment(
            result.draggableId,
            result.source.droppableId,
            result.destination.droppableId,
            result.destination.index
          ).catch((e) => {
            console.error(e);
          });
        }}
      >
        <AccordionGroup size="sm" disableDivider>
          <DemoWorkflow />
          <FavoriteWorkflows
            favorites={favoriteWorkflows ?? []}
            onAction={onAction}
          />
          {filteredDepartments?.map((department) => (
            <DepartmentWorkflows
              key={department.id}
              department={department}
              onAction={onAction}
            />
          ))}
        </AccordionGroup>
      </DragDropContext>
      {searchVisible && filteredDepartments?.length === 0 && (
        <div className="p-4">
          <Typography level="body-sm" fontStyle="italic">
            {t("noResults")}
          </Typography>
        </div>
      )}
    </div>
  );
}

function DepartmentWorkflows({
  department,
  onAction,
}: {
  department: Department;
  onAction?: () => void;
}) {
  const { t } = useTranslation();
  const displayName = department.isPersonal
    ? t("personalArea")
    : department.name;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (department.workflows.length === 0) setOpen(false);
    if (department.workflows.length > 0) setOpen(true);
  }, [department.workflows.length]);

  return (
    <Droppable
      droppableId={department.id}
      isCombineEnabled={false}
      isDropDisabled={!department.writePermission}
    >
      {(provided, snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={twMerge(
              "rounded border border-transparent",
              snapshot.isDraggingOver && "border-gray-600 bg-gray-300"
            )}
          >
            <Accordion
              expanded={open}
              onChange={(_, isOpen) => setOpen(isOpen)}
            >
              <AccordionSummary
                slotProps={{
                  button: {
                    sx: {
                      flexDirection: "row-reverse",
                      justifyContent: "flex-end",
                      gap: 0,
                      backgroundColor: snapshot.isDraggingOver
                        ? "inherit !important"
                        : undefined,
                    },
                  },
                }}
              >
                <div className="flex flex-row items-center justify-between pl-2">
                  <Typography level="title-sm" color="neutral">
                    {displayName}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <List size="sm">
                  {department.workflows
                    .sort((a, b) => a.index - b.index)
                    .map(({ id: workflowId }, i) => (
                      <Draggable
                        draggableId={workflowId}
                        index={i}
                        key={workflowId}
                        isDragDisabled={!department.writePermission}
                      >
                        {(providedDraggable) => (
                          <div
                            ref={providedDraggable.innerRef}
                            {...providedDraggable.dragHandleProps}
                            {...providedDraggable.draggableProps}
                          >
                            <WorkflowItem
                              workflowId={workflowId}
                              key={workflowId}
                              onAction={onAction}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {department.workflows.length === 0 && (
                    <Typography
                      level="body-sm"
                      color="neutral"
                      sx={{
                        px: 2,
                        py: 1,
                        fontStyle: "italic",
                      }}
                    >
                      {t("noWorkflows")}
                    </Typography>
                  )}
                  {department.workflows.length > 0 && provided.placeholder}
                </List>
              </AccordionDetails>
            </Accordion>
          </div>
        );
      }}
    </Droppable>
  );
}

function DemoWorkflow() {
  const { completed: demoFinished } = useGuide();
  return demoFinished ? null : (
    <div className="rounded border border-transparent">
      <Accordion expanded={true}>
        <AccordionSummary
          slotProps={{
            button: {
              sx: {
                flexDirection: "row-reverse",
                justifyContent: "flex-end",
                gap: 0,
              },
            },
          }}
        >
          <div className="flex flex-row items-center justify-between pl-2">
            <Typography level="title-sm" color="neutral">
              Demo Department
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <DemoWorkflowItem />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

function FavoriteWorkflows({
  favorites,
  onAction,
}: {
  favorites: Workflow[];
  onAction?: () => void;
}) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (favorites.length === 0) setOpen(false);
    if (favorites.length > 0) setOpen(true);
  }, [favorites.length]);

  return (
    <div className="rounded border border-transparent">
      <Accordion expanded={open} onChange={(_, isOpen) => setOpen(isOpen)}>
        <AccordionSummary
          slotProps={{
            button: {
              sx: {
                flexDirection: "row-reverse",
                justifyContent: "flex-end",
                gap: 0,
              },
            },
          }}
        >
          <div className="flex flex-row items-center justify-between pl-2">
            <Typography level="title-sm" color="neutral">
              {t("favorites")}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <List size="sm">
            {favorites
              .sort((a, b) => a.index - b.index)
              .map(({ id: workflowId }) => (
                <WorkflowItem
                  workflowId={workflowId}
                  key={workflowId}
                  onAction={onAction}
                />
              ))}
            {favorites.length === 0 && (
              <Typography
                level="body-sm"
                color="neutral"
                sx={{
                  px: 2,
                  py: 1,
                  fontStyle: "italic",
                }}
              >
                {t("noFavorites")}
              </Typography>
            )}
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
