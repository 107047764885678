import { Divider, IconButton } from "@mui/joy";
import { AutoAwesome, Message, PlayCircle } from "@mui/icons-material";
import { useNavigate, useParams } from "../../../router";
import ToolsList from "../ToolsList";
import { CollapsedSidebarHoverMenu } from "../CollapsedDropdownButton";
import { type BottomButton } from "../Sidebar";
import { comparePath } from "../../../lib/routeUtils";
import { useLocation } from "react-router-dom";
import { UserMenu } from "../../auth/UserMenu";
import { useOrganization } from "../../../lib/api/organization";
import ChatTree from "../ChatTree";
import { OrganizationTree } from "../OrganizationTree";
import { useState } from "react";

export function CollapsedSidebar({
  makeTourStep,
  bottomButtons,
}: {
  makeTourStep: () => void;
  bottomButtons: BottomButton[];
}) {
  const navigate = useNavigate();
  const { organizationId } = useParams("/:organizationId");
  const path = useLocation().pathname;
  const organization = useOrganization();

  const [chatsOpen, setChatsOpen] = useState(false);
  const [workflowsOpen, setWorkflowsOpen] = useState(false);

  const startNewChat = () => {
    navigate(`/:organizationId`, { params: { organizationId } });
    makeTourStep();
  };

  return (
    <div className="flex h-full w-full flex-col items-center justify-between gap-6 overflow-y-auto overflow-x-hidden pt-3">
      <div className="flex flex-col items-center gap-3">
        <IconButton
          variant="solid"
          color="primary"
          onClick={startNewChat}
          className="!mt-2"
        >
          <AutoAwesome fontSize="small" />
        </IconButton>

        <CollapsedSidebarHoverMenu
          open={chatsOpen}
          setOpen={setChatsOpen}
          icon={<Message />}
          content={
            <div className="p-2">
              <ChatTree collapsed />
            </div>
          }
        />
        <Divider />
        <ToolsList sidebarCollapsed />
        <Divider />

        <CollapsedSidebarHoverMenu
          open={workflowsOpen}
          setOpen={setWorkflowsOpen}
          icon={<PlayCircle />}
          content={
            <div className="p-2">
              <OrganizationTree
                onAction={() => {
                  setWorkflowsOpen(false);
                }}
              />
            </div>
          }
        />
      </div>
      <div className="flex flex-col gap-3">
        {bottomButtons.map((button) => {
          return (
            button.doesRender && (
              <IconButton
                style={{
                  backgroundColor:
                    button.url && comparePath(path, button.url)
                      ? "var(--joy-palette-neutral-plainActiveBg)"
                      : "transparent",
                }}
                key={button.text}
                onClick={
                  button.onClick ??
                  (() => {
                    button.url &&
                      navigate(button.url, {
                        params: {
                          organizationId,
                          workshopId: organization?.defaultWorkshopId ?? "",
                        },
                      });
                  })
                }
              >
                {button.icon}
              </IconButton>
            )
          );
        })}

        <Divider />
        <div className="mb-2">
          <UserMenu languageSelector />
        </div>
      </div>
    </div>
  );
}
