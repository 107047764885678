import { Delete, InsertDriveFile } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { readableFileSize } from "../../../../lib/util.ts";
import { ActionButtons } from "../ActionButtons";

export function FileChosen({
  file,
  setFile,
  handleTranslation,
  loading,
}: {
  file: File | undefined;
  setFile: (file: File | undefined) => void;
  handleTranslation: () => void;
  loading?: boolean;
}) {
  const { t } = useTranslation();

  const title = file?.name ?? "";

  return (
    <>
      <Stack alignItems="center" sx={{ width: "100%" }}>
        <InsertDriveFile color="primary" sx={{ fontSize: "40px", mb: 1 }} />
        <Typography
          level="body-md"
          sx={{
            width: "100%",
            maxWidth: "700px",
            whiteSpace: "nowrap",
            overflowX: "hidden",
            textOverflow: "ellipsis",
            textAlign: "center",
          }}
        >
          {title}
        </Typography>
        {file && (
          <Typography level="body-sm" color="neutral">
            ({readableFileSize(file.size)})
          </Typography>
        )}
      </Stack>

      {!loading && (
        <ActionButtons>
          <Button
            sx={{ maxWidth: "400px" }}
            size="lg"
            fullWidth
            onClick={handleTranslation}
          >
            {t("translate")}
          </Button>
          <Button
            size="sm"
            color="neutral"
            variant="plain"
            onClick={() => {
              setFile(undefined);
            }}
            endDecorator={<Delete />}
          >
            {t("remove")}
          </Button>
        </ActionButtons>
      )}
    </>
  );
}
