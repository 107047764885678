import { AudioFile, Close, CloudUpload, Info } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Option,
  Select,
  Switch,
  Textarea,
  Typography,
  Alert,
} from "@mui/joy";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { trpc } from "../../../../../lib/api/trpc/trpc.ts";
import { readableFileSize } from "../../../../../lib/util.ts";
import { Trans, useTranslation } from "react-i18next";
import { audioMimeType } from "../../../../../lib/constants/mime.ts";

export function Uploader({ onUpload }: { onUpload: () => void }) {
  const { mutateAsync: createAndUpload } =
    trpc.tools.meetingRecorder.createAndUpload.useMutation();

  const { mutateAsync: process } =
    trpc.tools.meetingRecorder.startTranscription.useMutation();

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const { t } = useTranslation();

  // TODO convert to formik
  const [displayName, setDisplayName] = useState<string | null>(null);
  const [cleanUpAudio, setCleanUpAudio] = useState(false);
  const [languageCode, setLanguageCode] = useState("de");
  const [numSpeakers, setNumSpeakers] = useState(2);
  const [advancedDiarization, setAdvancedDiarization] = useState(false);
  const [vocabulary, setVocabulary] = useState(
    localStorage.getItem("transcriptVocabulary") ?? ""
  );

  const [uploading, setUploading] = useState<boolean>(false);

  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (!file) {
      return;
    }
    if (!displayName) {
      setDisplayName(file.name);
    }
    setUploadedFile(file);
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept } =
    useDropzone({
      onDrop,
      disabled: uploading,
      accept: audioMimeType,
      multiple: false,
    });

  const createMeeting = async () => {
    if (!uploadedFile || !displayName) {
      return;
    }
    setUploading(true);
    const { uploadUrl, id: meetingId } = await createAndUpload({
      displayName,
      cleanUpAudio,
      languageCode,
      numSpeakers,
      advancedDiarization,
      vocabulary,
    });
    await fetch(uploadUrl, {
      method: "PUT",
      body: uploadedFile,
    });
    toast.success(t("tools.meetingTranscription.fileUploaded"));
    void process({ meetingId });
    onUpload();
  };

  let dropzoneText = t("tools.meetingTranscription.dragToUpload");
  if (isDragActive) {
    if (!isDragAccept) {
      dropzoneText = t("tools.meetingTranscription.mimeNotSupported");
    } else {
      dropzoneText = t("tools.meetingTranscription.dropFile");
    }
  }

  return (
    <div className="flex flex-col gap-8">
      <Typography level="title-lg">
        {t("tools.meetingTranscription.newMeeting")}
      </Typography>

      <FormControl required disabled={uploading}>
        <FormLabel>{t("tools.meetingTranscription.nameOfMeeting")}</FormLabel>
        <Input
          type="text"
          required
          value={displayName ?? undefined}
          onChange={(e) => {
            setDisplayName(e.target.value ?? null);
          }}
        />
      </FormControl>
      <FormControl required disabled={uploading}>
        <FormLabel>{t("tools.meetingTranscription.recording")}</FormLabel>
        <Box
          sx={{
            border: "2px dashed",
            borderColor: "neutral.outlinedBorder",
            borderRadius: "sm",
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
            height: 120,
            cursor: "pointer",
            "&:hover": {
              bgcolor: "background.level1",
            },
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />

          {uploadedFile ? (
            <Card
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 2,
                p: 1,
              }}
            >
              <AudioFile color="primary" sx={{ fontSize: 40 }} />
              <Box sx={{ flex: 1 }}>
                <Typography>{uploadedFile.name}</Typography>
                <Typography level="body-sm" color="neutral">
                  {readableFileSize(uploadedFile.size)}
                </Typography>
              </Box>
              <IconButton
                disabled={uploading}
                size="sm"
                variant="plain"
                color="neutral"
                onClick={(e) => {
                  e.stopPropagation();
                  setUploadedFile(null);
                }}
              >
                <Close />
              </IconButton>
            </Card>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: "center",
              }}
            >
              <CloudUpload sx={{ fontSize: 40 }} />
              <Typography textAlign="center">{dropzoneText}</Typography>
            </Box>
          )}
        </Box>
        <FormHelperText>
          {t("tools.meetingTranscription.supportedFormats", {
            formats: Object.values(audioMimeType)
              .flatMap((mime) => mime)
              .join(", "),
            length: "2h 15min",
          })}
        </FormHelperText>
      </FormControl>

      <div className="flex gap-4">
        <FormControl sx={{ flexGrow: 1 }} disabled={uploading}>
          <FormLabel>
            {t("tools.meetingTranscription.recordingLanguage")}
          </FormLabel>
          <Select
            value={languageCode}
            onChange={(_e, newValue) => setLanguageCode(newValue ?? "de")}
          >
            <Option value="de">{t("languages.de")}</Option>
            <Option value="en">{t("languages.en")}</Option>
            <Option value="fr">{t("languages.fr")}</Option>
            <Option value="it">{t("languages.it")}</Option>
            <Option value="es">{t("languages.es")}</Option>
          </Select>
        </FormControl>
        <FormControl sx={{ flexGrow: 1 }} disabled={uploading}>
          <FormLabel>
            {t("tools.meetingTranscription.numberOfSpeakers")}
          </FormLabel>
          <Select
            value={numSpeakers}
            onChange={(_e, newValue) => setNumSpeakers(Number(newValue))}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((value) => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </Select>
        </FormControl>
      </div>

      <FormControl
        orientation="horizontal"
        sx={{ gap: 2 }}
        disabled={uploading}
      >
        <Switch
          checked={cleanUpAudio}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setCleanUpAudio(event.target.checked)
          }
        />
        <div>
          <FormLabel sx={{ m: 0 }}>
            {t("tools.meetingTranscription.improveQuality.title")}
          </FormLabel>
          <FormHelperText sx={{ mt: 0 }}>
            {t("tools.meetingTranscription.improveQuality.subtitle")}
          </FormHelperText>
        </div>
      </FormControl>
      <FormControl
        orientation="horizontal"
        sx={{ gap: 2 }}
        disabled={uploading}
      >
        <Switch
          checked={advancedDiarization}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setAdvancedDiarization(event.target.checked)
          }
        />
        <div>
          <FormLabel sx={{ m: 0 }}>
            {t("tools.meetingTranscription.improveVoiceRecognition.title")}
          </FormLabel>
          <FormHelperText sx={{ mt: 0 }}>
            {t("tools.meetingTranscription.improveVoiceRecognition.subtitle")}
          </FormHelperText>
        </div>
      </FormControl>

      <FormControl disabled={uploading}>
        <FormLabel>
          {t("tools.meetingTranscription.vocabulary.title")}
        </FormLabel>
        <Textarea
          minRows={3}
          maxRows={10}
          value={vocabulary}
          placeholder="meinGPT, Jour Fixe, ..."
          onChange={(e) => {
            setVocabulary(e.target.value);
            localStorage.setItem("transcriptVocabulary", e.target.value);
          }}
        />
        <FormHelperText>
          {t("tools.meetingTranscription.vocabulary.subtitle")}
        </FormHelperText>
      </FormControl>

      <Alert startDecorator={<Info />}>
        <Trans>
          <Typography level="body-sm">
            {t("tools.meetingTranscription.infoProcessing", {
              credits: 100,
            })}
          </Typography>
        </Trans>
      </Alert>

      <Button
        onClick={createMeeting}
        disabled={!uploadedFile || !displayName || uploading}
        loading={uploading}
      >
        {t("tools.meetingTranscription.uploadAndTranscript")}
      </Button>
    </div>
  );
}
