import { Sheet, Typography } from "@mui/joy";

export default function ToolPage({
  title,
  subtitle,
  actions,
  children,
}: {
  title: string;
  subtitle?: string;
  actions?: React.ReactNode;
  children: React.ReactNode;
}) {
  return (
    <Sheet variant="soft" sx={{ minHeight: "100vh" }}>
      <div className="flex max-w-screen-2xl flex-col justify-center gap-10 p-10">
        <div className=" flex flex-wrap items-center justify-between gap-y-4">
          <div>
            <Typography level="h1">{title} </Typography>
            {subtitle && <Typography level="body-md">{subtitle}</Typography>}
          </div>
          {actions}
        </div>
        {children}
      </div>
    </Sheet>
  );
}
