import { toast } from "react-toastify";
import { documentMimeType } from "../../../../lib/constants/mime";
import { DocumentDropzone } from "../../../chat/attachments/DocumentDropzone";
import { useTranslation } from "react-i18next";

export function Ready({ setFile }: { setFile: (file: File) => void }) {
  const { t } = useTranslation();
  return (
    <DocumentDropzone
      style={{
        border: 0,
        textAlign: "center",
      }}
      customMime={documentMimeType}
      allowMultiple={false}
      onSelect={(files) => {
        if (files.length === 0) {
          toast.error(t("common.upload.failed", { fileName: "" }));
          return;
        }
        setFile(files[0]);
      }}
    />
  );
}
